import makeStyles from '@material-ui/core/styles/makeStyles';
import {Fonts} from '../../constants/AppEnums';
import {fade} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  '@global': {
    // for global styles
    '.MuiLink-root': {
      fontWeight: Fonts.REGULAR,
    },
    '.pointer': {
      cursor: 'pointer',
    },
    '.MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.background.paper,
    },
    '.item-hover': {
      transition: 'all .2s ease',
      transform: 'scale(1)',
      '&:hover': {
       /*  backgroundColor: fade(theme.palette.primary.main, 0.1), */
        transform: 'translateY(-2px)',
       /*  boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.black, 0.2)}`, */
      },
    },
    '.card-hover': {
      transition: 'all 0.3s ease',
      transform: 'scale(1)',
      '&:hover': {
      /*   boxShadow: '0 4px 8px rgba(0,0,0,.45)', */
        transform: 'scale(1.05)',
      },
    },
	'.dropzone':{
    height: '198px',
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		background: '#F4F4F4',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out',
    justifyContent: 'center',
    cursor: 'pointer !important'
	},
  '.dropzone p': {
    fontFamily: 'BBVA Benton Sans Book !important',
    fontstyle:' normal !important',
    fontsize: '16px !important',
    lineHeight:' 21px !important',
    color: '#666666 !important',
  },
  '.dropzone h3': {
    fontFamily: 'BBVA Benton Sans Bold !important',
    fontStyle:' normal !important',
    fontSize: '18px !important',
    lineHeight: '24px !important',
    textAlign: 'center !important',
    textDecorationLine: 'underline !important',
    color: '#1973B8 !important',
  },
  '.dropzone em': {
    fontFamily:' BBVA Benton Sans Book !important',
    fontStyle: 'normal !important',
    fontSize: '12px !important',
    lineHeight: '16px !important',
    color: '#666666 !important',
  }
  
  },
}));

export default useStyles;
