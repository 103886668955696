import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const ecommerceConfig = [
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/invoice-1',
        component: React.lazy(() => import('./Invoice1')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/invoice-2',
        component: React.lazy(() => import('./Invoice2')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/products',
        component: React.lazy(() => import('./Products')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/product_detail/:id?',
        component: React.lazy(() => import('./ProductDetail')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/quizzes',
        component: React.lazy(() => import('./Quizzes')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/quiz/add-quiz',
        component: React.lazy(() => import('./Quizzes/Quiz')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/quiz/edit-quiz/:id?',
        component: React.lazy(() => import('./Quizzes/Quiz')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/checkout',
        component: React.lazy(() => import('./Checkout')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/ecommerce/cart',
        component: React.lazy(() => import('./Carts')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/programs',
        component: React.lazy(() => import('./Programs')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/add-program',
        component: React.lazy(() => import('./ProgramsAdd')),
      },
    ],
  },
  
  {
    auth: authRole.user,
    routes: [
      {
        path: '/edit-program/:id?',
        component: React.lazy(() => import('./ProgramsAdd/')),
      },
    ],
  },

  {
    auth: authRole.user,
    routes: [
      {
        path: '/add-module',
        component: React.lazy(() => import('./ModulesAdd')),
      },
    ],
  },

  {
    auth: authRole.user,
    routes: [
      {
        path: '/modules',
        component: React.lazy(() => import('./Modules')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/edit-module/:id?',
        component: React.lazy(() => import('./ModulesAdd/')),
      },
    ],
  },

  {
    auth: authRole.user,
    routes: [
      {
        path: '/news',
        component: React.lazy(() => import('./News')),
      },
    ],
  },
   {
     auth: authRole.user,
     routes: [
       {
         path: '/add-news',
         component: React.lazy(() => import('./NewsAdd')),
       },
     ],
   },
   {
     auth: authRole.user,
     routes: [
       {
         path: '/edit-news/:id?',
         component: React.lazy(() => import('./NewsAdd/')),
       },
     ],
   },
   {
    auth: authRole.user,
    routes: [
      {
        path: '/edit-config/:id?',
        component: React.lazy(() => import('./ConfigurationAdd/')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/learning-objects',
        component: React.lazy(() => import('./Orders')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/trivias',
        component: React.lazy(() => import('./Trivias')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/add-trivia',
        component: React.lazy(() => import('./TriviasCreate')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/edit-trivia/:trivia_id?',
        component: React.lazy(() => import('./TriviasCreate')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/learning-object/add-learning-object',
        component: React.lazy(() => import('./LearningObject')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/learning-object/edit-learning-object/:id?',
        component: React.lazy(() => import('./LearningObject/')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/users',
        component: React.lazy(() => import('./Customers')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/dashboards',
        component: React.lazy(() => import('./Dashboards/index')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/users-detail/:id?',
        component: React.lazy(() => import('./CustomersDetail/')),
      },
    ],
  },
 /*  {
    auth: authRole.user,
    routes: [
      {
        path: '/actividades',
        component: React.lazy(() => import('./objectsCards/')),        
      },
    ],
  }, */
  {
    auth: authRole.user,
    routes: [
      {
        path: '/configuracion',
        component: React.lazy(() => import('./Configuration/')),        
      },
    ],
  },
		{
			auth: authRole.user,
			routes: [
			  {
				path: '/add-discipline',
				component: React.lazy(() => import('./Discipline/')),
			  },
			],
		  },

  {
    auth: authRole.user,
    routes: [
      {
        path: '/edit-discipline/:id?',
        component: React.lazy(() => import('./Discipline/')),
      },
    ],
  },
  {
    auth: authRole.user,
    routes: [
      {
        path: '/help',
        component: React.lazy(() => import('./Help/index')),
      },
    ],
  },
 /*  {
    routes: [
      {
        path: '/employeeID/:id?/password/:pw?',
        component: React.lazy(() => import('../../modules/auth/Login/index')),
      },
    ],
  }, */
];
