import {
  ADD_CART_ITEM,
  GET_CUSTOMERS,
  GET_ECOMMERCE_LIST,
  GET_RECENT_ORDER,
  REMOVE_CART_ITEM,
  SET_CART_ITEMS,
  SET_FILTER_DATA,
  SET_PRODUCT_DATA,
  SET_PRODUCT_VIEW_TYPE,
  UPDATE_CART_ITEM,
  CREATE_NEW_OBJECT,
  GET_OBJECT,
  UPDATE_OBJECT,
  DELETE_OBJECT,
  GET_MODULES,
  REMOVE_MODULE,
  GET_MODULE_BY_OBJECT,
  GET_MODULE_BY_QUIZ,
  ASSIGN_NEW_OBJECT,
  FETCH_ERROR,
  FETCH_SUCCESS,
  GET_PROGRAMS,
  REMOVE_PROGRAM,
  GET_NEWS,
  REMOVE_NEWS,
  UPLOAD_NEW_FILE,
  GET_QUIZZES,
  CREATE_NEW_QUIZ,
  GET_QUIZ,
  DELETE_QUIZ,
  UPDATE_QUIZ,
  ASSIGN_NEW_QUIZ,
  GET_CUSTOMERSDETAIL,
  GET_PROGRAMSASSIGNED,
  GET_OBJECTS,
  GET_CONFIGURATION,
  GET_TRIVIAS,
  GET_TRIVIA,
  DELETE_TRIVIA,
  UPDATE_CONFIGURATION,
  DELETE_PROGRAM,
  DELETE_MODULE
} from "../../shared/constants/ActionTypes";
import { cartItems } from "../../@crema/services/db/ecommerce/ecommerceData";

export const VIEW_TYPE = Object.freeze({ LIST: 1, GRID: 2 });
const initialState = {
  ecommerceList: [],
  viewType: VIEW_TYPE.LIST,
  currentProduct: null,
  orderCount: 0,
  filterData: {
    title: "",
    brand: [],
    ideaFor: [],
    discount: [],
    color: [],
    rating: [],
  },
  cartItems: cartItems,
  recentOrders: [],
  trivias: [],
  trivia: null,
  triviaCount: 0,
  customers: [],
  customerCount: 0,
  objectsx: [],
  objectxCount: 0,
  object: [],
  modules: [],
  news: [],
  objectAssingation: [],
  quizAssingation: [],
  object_module: [],
  quiz_module: [],
  errorMsg: "",
  successMsg: "",
  programs: [],
  quizzes: [],
  quiz: [],
  customersdetail: {},
  programsassigned: [],
  configuration: {},
  awsS3: null,
  deletePrograms: [],
  deleteModules: []
};

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ECOMMERCE_LIST:
      return {
        ...state,
        ecommerceList: action.payload,
      };
    case SET_PRODUCT_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: action.payload,
      };

    case SET_PRODUCT_DATA:
      return {
        ...state,
        currentProduct: action.payload,
      };

    case GET_RECENT_ORDER:
      return {
        ...state,
        recentOrders: action.payload,
        orderCount: action.payload.length,
      };

    case GET_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
        programsCount: action.payload.length,
      };

    case REMOVE_PROGRAM:
      return {
        ...state,
        programs: state.programs.filter(
          (item) => item.program_id !== action.payload
        ),
      };

    case GET_TRIVIAS:
      return {
        ...state,
        trivias: action.payload,
        triviaCount: action.payload.length,
      };

    case GET_TRIVIA:
      return {
        ...state,
        trivia: action.payload,
      };

    case DELETE_TRIVIA:
      return {
        ...state,
        successMsg: "deleted",
      };

    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
        newsCount: action.payload.length,
      };

    case REMOVE_NEWS:
      return {
        ...state,
        news: state.news.filter((item) => item.news_id !== action.payload),
      };

    case SET_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };

    case UPDATE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case ADD_CART_ITEM:
      console.log("action.payload", action.payload, state.cartItems);

      let cartItems = [];
      if (state.cartItems.some((item) => +item.id === +action.payload.id)) {
        console.log("updating");
        cartItems = state.cartItems.map((item) => {
          if (+item.id === +action.payload.id) {
            item.count = +item.count + 1;
          }
          return item;
        });
        return {
          ...state,
          cartItems: cartItems,
        };
      } else {
        console.log("adding");
        cartItems = state.cartItems.concat({
          id: action.payload.id,
          title: action.payload.title,
          mrp: action.payload.mrp,
          discount: action.payload.discount,
          brand: action.payload.brand,
          image: action.payload.image[0],
          count: 1,
        });
        return {
          ...state,
          cartItems: cartItems,
        };
      }

    case REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.id !== action.payload.id
        ),
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        customerCount: action.payload.length,
      };
    case GET_QUIZZES:
      return {
        ...state,
        quizzes: action.payload,
        successMsg: "",
      };
    case CREATE_NEW_QUIZ:
      return {
        ...state,
        quiz: action.payload,
        successMsg: "exito",
      };
    case GET_QUIZ:
      return {
        ...state,
        quiz: action.payload,
        successMsg: "",
      };
    case UPDATE_QUIZ:
      return {
        ...state,
        quiz: action.payload,
        successMsg: "exito",
      };
    case CREATE_NEW_OBJECT:
      return {
        ...state,
        object: action.payload,
      };
    case ASSIGN_NEW_OBJECT:
      return {
        ...state,
        object_module: action.payload,
      };
    case ASSIGN_NEW_QUIZ:
      return {
        ...state,
        quiz_module: action.payload,
      };
    case GET_OBJECT:
      return {
        ...state,
        object: action.payload,
      };
    case UPDATE_OBJECT:
      return {
        ...state,
        object: action.payload,
      };
    case DELETE_OBJECT:
    case DELETE_QUIZ:
      return {
        ...state,
        successMsg: "deleted",
      };
    case GET_MODULE_BY_OBJECT:
      return {
        ...state,
        objectAssingation: action.payload,
      };
    case GET_MODULE_BY_QUIZ:
      return {
        ...state,
        quizAssingation: action.payload,
      };
    case GET_MODULES:
      return {
        ...state,
        modules: action.payload,
        modulesCount: action.payload.length,
      };
    case REMOVE_MODULE:
      return {
        ...state,
        modules: state.modules.filter(
          (item) => item.module_id !== action.payload
        ),
      };
    case GET_OBJECTS:
      return {
        ...state,
        objectsx: action.payload,
        objectsxCount: action.payload.length,
      };
    case GET_CUSTOMERSDETAIL:
      return {
        ...state,
        customersdetail: action.payload,
      };
    case GET_PROGRAMSASSIGNED:
      return {
        ...state,
        programsassigned: action.payload,
      };
    case GET_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload,
        // configurationCount: action.payload.length,
      };
    case DELETE_PROGRAM:
      return {
        ...state,
        deletePrograms: action.payload,
      };
    case DELETE_MODULE:
      return {
        ...state,
        deleteModules: action.payload,
      };
    case UPLOAD_NEW_FILE:
      return {
        ...state,
        awsS3: action.payload,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        successMsg: "exito",
        errorMsg: "",
      };
    case FETCH_ERROR:
      return {
        ...state,
        successMsg: "",
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};
export default ecommerceReducer;
