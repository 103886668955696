import { authRole } from '../shared/constants/AppConst';

const routesConfig = [
	{
		id: 'app',
		title: 'Dashboard',
		messageId: 'sidebar.triatlon',
		type: 'group',
		children: [
			{
				id: 'dashboards',
				title: 'Dashboard',
				messageId: 'sidebar.dashboards',
				type: 'item',
				icon: './assets/images/ico-dashboard.svg',
				url: '/dashboards',
			},
			{
				id: 'users',
				title: 'Users',
				messageId: 'sidebar.ecommerce.customers',
				type: 'item',
				icon: './assets/images/ico-usuarios.svg',
				url: '/users',
			},
			/* {
				id: 'news',
				title: 'News',
				messageId: 'sidebar.news',
				type: 'item',
				icon: './assets/images/ico-noticias.svg',
				url: '/news',
			}, */
			{
				id: 'programs',
				title: 'Programs',
				messageId: 'sidebar.programs',
				type: 'item',
				icon: './assets/images/ico-programas.svg',
				url: '/programs',
			},
			{
				id: 'orders',
				title: 'Modules',
				messageId: 'sidebar.modules',
				type: 'item',
				icon: './assets/images/ico-modulos.svg',
				url: '/modules',
			},
			
			{
				id: 'objects',
				title: 'Object',
				messageId: 'sidebar.learningobjects',
				type: 'item',
				icon: './assets/images/ico-objetos-aprendizaje.svg',
				url: '/learning-objects',
			},
			{
				id: 'trivias',
				title: 'Trivias',
				messageId: 'sidebar.trivias',
				type: 'item',
				icon: './assets/images/ico-evaluaciones.svg',
				url: '/trivias',
			},
			{
				id: 'quizzes',
				title: 'Quizzes',
				messageId: 'sidebar.ecommerce.quizzes',
				type: 'item',
				icon: './assets/images/ico-quiz.svg',
				url: '/quizzes',
			},
			/* {
				id: 'activities',
				title: 'Actividades',
				messageId: 'sidebar.objectCards',
				type: 'item',
				icon: './assets/images/actividades.svg',
				url: '/actividades',
			}, */
			{
				id: 'configuration',
				title: 'Configuración',
				messageId: 'sidebar.configuration',
				type: 'item',
				icon: './assets/images/ico-configuracion.svg',
				url: '/configuracion',
			},
			{
				id: 'help',
				title: 'Help',
				messageId: 'sidebar.help',
				type: 'item',
				icon: './assets/images/ico-soporte.svg',
				url: '/help',
			},
		],
	},

];
export default routesConfig;
