import React from 'react';
import List from '@material-ui/core/List';

import routesConfig from '../../../../modules/routesConfig';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';

import { useDispatch } from 'react-redux';
import {
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
} from '../../../../redux/actions';
import { AuthType } from '../../../../shared/constants/AppEnums';
import { useSelector } from 'react-redux';

const Navigation = () => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  return (
    <>
      <List>
        {routesConfig.map((item) => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && <VerticalNavGroup item={item} level={0} />}

            {item.type === 'collapse' && (
              <VerticalCollapse item={item} level={0} />
            )}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </List>
      <button className="cerrar-sesion"
        onClick={() => {
          if (user && user.authType === AuthType.AWS_COGNITO) {
            dispatch(onCognitoUserSignOut());
          } else if (user && user.authType === AuthType.FIREBASE) {
            dispatch(onSignOutFirebaseUser());
          } else if (user && user.authType === AuthType.AUTH0) {
            dispatch(onSignOutAuth0User());
          } else if (user && user.authType === AuthType.JWT_AUTH) {
            dispatch(onJWTAuthSignout());
          }
        }}>
        <img src="./assets/images/ico-salir.svg" style={{marginRight: 10}} />
        Salir
      </button>
    </>
  );
};

export const useAuthUser = () => {
  const {user} = useSelector(({auth}) => auth);

  if (user) {
    return {id: 1, ...user};
  }
  return [null];
};

export default Navigation;
