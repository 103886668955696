import {
  employeeIDVerification,
  loginWithEmployeeIDAndPassword,
  loginWithToken,
  getInfoEmployeeID
} from "../services/auth";
import { AUTH_TYPES } from "./authActionTypes";
import AutoToken from "../shared/authToken";
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_AUTH_USER} from '../../shared/constants/ActionTypes';
import {AuthType} from '../../shared/constants/AppEnums';

export const doEmployeeIDVerificationStart = ({ employeeid }) => {
  return {
    type: AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_START,
    payload: employeeid,
  };
};

export const doEmployeeIDVerificationSuccess = (payload) => {
  return {
    type: AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS,
    payload,
  };
};

export const doEmployeeIDVerificationError = (error) => {
  return {
    type: AUTH_TYPES.AUTH_EMPLOYEE_ID_VERIFICATION_ERROR,
    payload: error,
  };
};

export const doEmployeeIDTokenStart = () => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_START,
  };
};

export const doEmployeeIDTokenSuccess = (payload) => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_SUCCESS,
    payload,
  };
};

export const doEmployeeIDTokenError = (error) => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_TOKEN_ERROR,
    payload: error,
  };
};

export const startLogin = (payload) => {
  return async (dispatch) => {
    dispatch(doEmployeeIDVerificationStart(payload));
    try {
      const resID = await employeeIDVerification(payload.employeeid);
      const { data, status } = resID;

      if (data.code === "1") {
        dispatch(doEmployeeIDVerificationError({ ...data, status }));
        return;
      }
    } catch (e) {
      const { data, status } = e.response;

      if (status === 401 && data.code === "2") {
        //User exists and must call login with password service
        try {
          dispatch(doLoginWithPasswordStart());

          const res = await loginWithEmployeeIDAndPassword(payload);
          const { data } = res;

          localStorage.setItem("employeeID", payload.employeeid);
          AutoToken.set(data.data.token, true);
          dispatch(doLoginWithPasswordSuccess(data));
          window.location.reload("/");
        } catch (err) {
          const { data, status } = err.response;
          dispatch(doLoginWithPasswordError({ ...data, status }));
        } finally {
          return;
        }
      }

      dispatch(doEmployeeIDVerificationError({ ...data, status }));
    }
  };
};

export const doLoginWithPasswordStart = () => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_START,
  };
};

export const doLoginWithPasswordSuccess = (payload) => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_SUCCESS,
    payload,
  };
};

export const doLoginWithPasswordError = (error) => {
  return {
    type: AUTH_TYPES.AUTH_LOGIN_WITH_PASSWORD_ERROR,
    payload: error,
  };
};

export const startRegister = (payload) => {
  return async (dispatch) => {
    dispatch(doEmployeeIDVerificationStart(payload));
    try {
      const resID = await employeeIDVerification(payload.employeeid);
      const { data, status } = resID;

      if (status === 200 && data.code === "1") {
        //User exists in catalog and must register
        try {
          dispatch(doLoginWithPasswordStart());

          const res = await loginWithEmployeeIDAndPassword(payload);
          const { data } = res;

          AutoToken.set(data.data.token, true);
          localStorage.setItem("employeeID", payload.employeeid);
          dispatch(doLoginWithPasswordSuccess(data));
          window.location.reload("/");
        } catch (err) {
          const { data, status } = err.response;
          dispatch(
            doLoginWithPasswordError({ ...data, status, flowRegister: true })
          );
        }
      }
    } catch (e) {
      const { data, status } = e.response;

      dispatch(
        doEmployeeIDVerificationError({ ...data, status, flowRegister: true })
      );
    }
  };
};

export const startLoginToken = () => {
  return async (dispatch) => {
    dispatch(doEmployeeIDTokenStart());
    try {
      const token = AutoToken.get();

      if (!token) {
        dispatch(doEmployeeIDTokenError({ ...data, status }));
        return;
      }

      const resID = await loginWithToken(token);
      const { data, status } = resID;

      localStorage.setItem("employeeID", data.data.employeeid);
      dispatch(doEmployeeIDTokenSuccess(data));
      window.location.reload("/");
    } catch (e) {
      const token = AutoToken.get();

      if (!token) return;

      const { data, status } = e.response;
      AutoToken.setRemove(true);
      dispatch(doEmployeeIDTokenError({ ...data, status }));
    }
  };
};


export const resetStore = () => {
  return {
    type: AUTH_TYPES.AUTH_LOGOUT_RESET_STORE
  }
}
