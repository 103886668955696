const prefix = "AUTH";

export const AUTH_TYPES = {
  AUTH_EMPLOYEE_ID_VERIFICATION_START: `${prefix}_EMPLOYEE_ID_VERIFICATION_START`,
  AUTH_EMPLOYEE_ID_VERIFICATION_SUCCESS: `${prefix}_EMPLOYEE_ID_VERIFICATION_SUCCESS`,
  AUTH_EMPLOYEE_ID_VERIFICATION_ERROR: `${prefix}_EMPLOYEE_ID_VERIFICATION_ERROR`,

  AUTH_LOGIN_WITH_PASSWORD_START: `${prefix}_LOGIN_WITH_PASSWORD_START`,
  AUTH_LOGIN_WITH_PASSWORD_SUCCESS: `${prefix}_LOGIN_WITH_PASSWORD_SUCCESS`,
  AUTH_LOGIN_WITH_PASSWORD_ERROR: `${prefix}_LOGIN_WITH_PASSWORD_ERROR`,

  AUTH_LOGIN_WITH_TOKEN_START: `${prefix}_LOGIN_WITH_TOKEN_START`,
  AUTH_LOGIN_WITH_TOKEN_SUCCESS: `${prefix}_LOGIN_WITH_TOKEN_SUCCESS`,
  AUTH_LOGIN_WITH_TOKEN_ERROR: `${prefix}_LOGIN_WITH_TOKEN_ERROR`,

  AUTH_LOGOUT_RESET_STORE: `${prefix}_LOGOUT_RESET_STORE`,


};
