import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/login',
        component: React.lazy(() => import('./loginSaml/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/intelego',
        component: React.lazy(() => import('./Login/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/register',
        component: React.lazy(() => import('./Register/index')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/enter-password',
        component: React.lazy(() => import('./Password/EnterPasswordScreen')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/create-password',
        component: React.lazy(() => import('./Password/CreatePasswordScreen')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
