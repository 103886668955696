import AutoAxios from "../shared/axios/authAxios";

export const employeeIDVerification = (employeeid) => {
  return AutoAxios.post(
    `/login`,
    {
      employeeid,
    },
  );
};

export const loginWithEmployeeIDAndPassword = ({ employeeid, password }) => {
  return AutoAxios.post(
    `/login`,
    {
      employeeid,
      password,
    },
  );
};


export const loginWithToken = () => {
  return AutoAxios.post(
    `/login`
  );
  };

  export const getInfoEmployeeID = (employeeid) => {
    return AutoAxios.get(`/users/employee/${employeeid}`);
  };