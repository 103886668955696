import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export default axios.create({
	baseURL: `${API_URL}`,
	headers: {
		'Content-Type': 'application/json',
		//  'Access-Control-Allow-Origin': '*',
		// 'Cookie': 'AWSALB=30MAMlF3rQNXdTC2FlCVg97Mbhq3QDZd5W7k671RPM97Yk1aJlUnyAbPy/546E+tkKYzZmTKDZAum4PYfiP3fHsDw+SBJh4KXg+01NXGzWY7Da7PVJOAWBMkYRxJ; AWSALBCORS=30MAMlF3rQNXdTC2FlCVg97Mbhq3QDZd5W7k671RPM97Yk1aJlUnyAbPy/546E+tkKYzZmTKDZAum4PYfiP3fHsDw+SBJh4KXg+01NXGzWY7Da7PVJOAWBMkYRxJ; connect.sid=s%3AroWzS9ww4x-X8tmKz2D2wEg5IljL9yRj.2Jnl2r9i%2F%2BhQOJyEcQEPLAIUUaf%2Fk6Yk0o%2BIA05Jqu8',
		// 'Cache-Control': '*',
		'Accept': '*/*',
		// 'Connection': 'keep-alive',
		'x-bbvaton': localStorage.getItem('token') || ''
	},
	timeout: 600000
});

